import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
// import { gsap } from "gsap";
// import { SplitText } from "gsap/SplitText";
import { Container, Row, Col } from 'react-bootstrap';
import './hero.scss'

export const fragment = graphql`
  fragment HeroSection on WpPage_Pagebuilder_Layouts_Hero {
		title
		text
		button {
			target
			title
			url
		}
		backgroundChoice
		backgroundImage {
			remoteFile {
				childImageSharp {
					fluid(maxWidth: 800, quality: 70) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
		foregroundImage {
			remoteFile {
				childImageSharp {
					fluid(maxWidth: 800, quality: 70) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	}
`


const Hero = ({ title, text, button, backgroundChoice, backgroundImage, foregroundImage }) => (
	<header className={`block hero ${backgroundChoice}`}>
		<Container fluid="xl" >
			<Row className="align-items-center">
				<Col md={6}>
					<div className="text">
					{title && <h1>{title}</h1>}
					{text && <div dangerouslySetInnerHTML={{ __html: text }} />}
					{button && <a href={button.url} className="button primary">{button.title}</a>}
					</div>
				</Col>
				{backgroundChoice === 'featured' && <Img fluid={backgroundImage.remoteFile.childImageSharp.fluid} 
				style={{
					position: "absolute",
					left: 0,
					top: 0,
					width: "100%",
					height: "100%"
				}}/>}
				{backgroundChoice === 'media' && <Col md={6}><div className="media-container"><Img fluid={foregroundImage.remoteFile.childImageSharp.fluid} /></div></Col>}
			</Row>
		</Container>
	</header>
);

// const Hero = ({title, text, backgroundImage}) => (
// 	<section className="block hero">
// 		<Heading as="h1">{title}</Heading>
// 		<p>{text}</p>
// 		<Img fluid={backgroundImage.remoteFile.childImageSharp.fluid} />
// 	</section>
// )

export default Hero