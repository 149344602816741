import React,  { useEffect, useRef } from "react" // add useEffect, useRef
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap';
import "./media-grid.scss"
import { gsap } from "gsap";
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import backgroundImage from "../../../assets/images/topography.webp";

var background = {
  backgroundImage: 'url(' + backgroundImage + ')',
};



function MediaGrid ({title, text, media}) {

	// Create variables
	let slider = useRef(null);
	// let slide = useRef(null);

	// var numSlides = media.length;
	// var itemWidth = slide.viewport.clientWidth

	// console.log(itemWidth);

	
	useEffect(() => {
		gsap.registerPlugin(Draggable, InertiaPlugin);


		// Make slider draggable
    Draggable.create(slider, {
			type: "x",
			inertia: true,
		}, []);
		

		// Animate slides from left to right
		// gsap.to(slider, {
		// 	duration: 5,
		// 	ease: "none",
		// 	x: "+=500", //move each box 500px to right
		// 	modifiers: {
		// 		x: gsap.utils.unitize(x => parseFloat(x) % 500) //force x value to be between 0 and 500 using modulus
		// 	},
		// 	repeat: -1
		// });

  });
	
	
	return (
		<section className="block media-grid" style={background}>
			<Container fluid="xl">
				<Row>
					<Col md={{ span: 8, offset: 2 }} >
						{title && <h2>{title}</h2>}
						{text && <div dangerouslySetInnerHTML={{ __html: text }} />}
					</Col>
				</Row>
			</Container>


			<div className="slides-container" ref={el => slider = el}>
				{media && media.map((media, index) => (
					<div key={index} className="slideContainer">
					<div className="slide">
						{media.slide && media.slide.map((slide, index) => (
							<div key={index} className="img-wrap">
								<Img key={slide.id} fluid={slide.remoteFile.childImageSharp.fluid}
								style={{
									position: "absolute",
									left: 0,
									top: 0,
									width: "100%",
									height: "100%"
								}}
								/>
							</div>
						))}
					</div>
					</div>
				))}
			</div>

		</section>
	)
}

export default MediaGrid

export const fragment = graphql`
  fragment MediaGridSection on WpPage_Pagebuilder_Layouts_MediaGrid {
	title
	text
	media {
		slide {
			id
			remoteFile {
				childImageSharp {
					fluid(maxWidth: 350, quality: 70) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	}
}
`