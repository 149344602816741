import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/blocks/hero/hero"
import MediaWithText from "../../components/blocks/media-with-text/media-with-text"
import MediaGrid from "../../components/blocks/media-grid/media-grid"
import Columns from "../../components/blocks/columns/columns"
import Callout from "../../components/blocks/callout/callout"
import Cards from "../../components/blocks/cards/cards"
import SEO from "../../components/seo"


const Page = ({ data }) => {
  
  const sections = data.wpPage.pageBuilder.layouts;

  // Check if hero component is on page
  let isHeroVisible = false;
  sections.forEach(section => {
    let typeName = section.__typename;
    if ( typeName = 'WpPage_Pagebuilder_Layouts_Hero') { 
      isHeroVisible = true
      }
  });
  
  
  return (
    <Layout isHeroVisible={isHeroVisible}>
      <SEO
        title={data.wpPage.seo.title}
        description={data.wpPage.seo.metaDesc}
      />
      <main>
      {sections.map(section => {
        const typeName = section.__typename;

        const mappings = {
          'WpPage_Pagebuilder_Layouts_Hero': <Hero key={section.id} {...section} />,
          'WpPage_Pagebuilder_Layouts_Cards': <Cards key={section.id} {...section} />,
          'WpPage_Pagebuilder_Layouts_MediaWithText': <MediaWithText key={section.id} {...section} />,
          'WpPage_Pagebuilder_Layouts_MediaGrid': <MediaGrid key={section.id} {...section} />,
          'WpPage_Pagebuilder_Layouts_Columns': <Columns key={section.id} {...section} />,
          'WpPage_Pagebuilder_Layouts_Callout': <Callout key={section.id} {...section} />,
        }
        
        return mappings[typeName]
        
      })}
      </main>
    </Layout>
  );

};

export default Page



export const pageQuery = graphql`
query GET_PAGE($uri: String!) {
  wpPage(uri: { eq: $uri }) {
      seo {
        title
        metaDesc
      }
      id
      title
      pageBuilder {
        layouts {
          ... HeroSection
          ... MediaWithTextSection
          ... MediaGridSection
          ... ColumnsSection
          ... CalloutSection
          ... CardsSection
        }
      }
    }
  }
`
