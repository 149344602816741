import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import { Container, Row } from 'react-bootstrap';
import backgroundImage from "../../../assets/images/topography.webp";
import "./callout.scss";

var background = {
  backgroundImage: 'url(' + backgroundImage + ')',
};

export const fragment = graphql`
  fragment CalloutSection on WpPage_Pagebuilder_Layouts_Callout {
	title
	calloutButton {
		buttonStyle
		link {
			target
			title
			url
		}
	}
}
`

const Callout = ({title, calloutButton}) => (
	<section className="block callout" style={background}>
		<Container fluid="xl">
			<Row className="group">
				{title && <h3>{title}</h3>}
				<div className="links">
				{calloutButton.map((calloutButton, index) => (
					<>
						{calloutButton.link && <a key={index} href={calloutButton.link.url} className={calloutButton.buttonStyle}>{calloutButton.link.title}</a>}
					</>
				))}
				</div>
			</Row>
		</Container>
	</section>
)

export default Callout