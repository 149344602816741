import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col }from 'react-bootstrap';
import "./media-with-text.scss";

export const fragment = graphql`
	fragment MediaWithTextSection on WpPage_Pagebuilder_Layouts_MediaWithText {
		text
    title
    button {
      title
      url
      target
    }
    layout
    image {
      remoteFile {
        childImageSharp {
          fluid(maxWidth: 600, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
	}
`

const MediaWithText = ({title, text, button, image, layout}) => (
  <section className={`block media-with-text ${layout}`}>
    <Container fluid="xl" >
      <Row className="align-items-center">
        <Col md={6} className="media-container">
            <Img fluid={image.remoteFile.childImageSharp.fluid}
            sizes={{ ...image.remoteFile.childImageSharp.fluid, aspectRatio: 5 / 4 }}
            />
        </Col>
        <Col md={6} className="text">
            {title && <h3>{title}</h3>}
            {text &&  <div dangerouslySetInnerHTML={{ __html: text }} />}
            {button && <a href={button.url} className="button secondary">{button.title}</a>}
        </Col>
      </Row>
    </Container>
  </section>
)

export default MediaWithText