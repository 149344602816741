import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap';
import "./columns.scss"

export const fragment = graphql`
  fragment ColumnsSection on WpPage_Pagebuilder_Layouts_Columns {
	sectionText
	sectionTitle
	column {
		image {
			remoteFile {
				childImageSharp {
				fluid(maxWidth: 500, quality: 100) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
		title
		text
		button {
			url
			title
			target
		}
	}
}
`

const Columns = ({sectionText, sectionTitle, column }) => (
	<section className="block columns">
		<Container fluid="xl">
			<Row className="group">
				<Col md={7}>
				{sectionTitle && <h2>{sectionTitle}</h2>}
				{sectionText && <div dangerouslySetInnerHTML={{ __html: sectionText }} />}
				</Col>
			</Row>
			<Row>
				{column.map((column, index) => (
					<Col key={index} md={6} lg={3} className="column">
						{column.image && 
						<Img fluid={column.image.remoteFile.childImageSharp.fluid}
						sizes={{ ...column.image.remoteFile.childImageSharp.fluid, aspectRatio: 5 / 4 }}
						className="column-image"
						/>}
						{column.title && <h5>{column.title}</h5>}
						{column.text && <p>{column.text}</p>}
						{column.button && <a href={column.button.url}>{column.button.title}</a>}
					</Col>
				))}
			</Row>
		</Container>
	</section>
)

export default Columns